import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Dialog, Transition } from '@headlessui/react'
import { Link, withRouter, useHistory } from 'react-router-dom'
import {
  CogIcon,
  QuestionMarkCircleIcon,
  ShieldCheckIcon,
  XIcon,
} from '@heroicons/react/outline'


const secondaryNavigation = [
  { name: 'Settings', href: '#', icon: CogIcon },
  { name: 'Policies', href: '#', icon: ShieldCheckIcon },
  { name: 'Help', href: '#', icon: QuestionMarkCircleIcon },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Sidebar({location: {pathname}}) {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const {role} = useSelector(state=>state.user)

  const menuData = useSelector(state=>state.menu.menuData) || []

 
  const [activeItem, setActiveItem] = useState('')

  const [innerLink, setInnerLink] = useState(false)

  const history = useHistory()

  useEffect(() => {
    setActiveItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, menuData])

  const setActiveItems = () => {
    if (!menuData.length) {
      return
    }
    const currentItem = menuData.navigation.filter(obj=> pathname.includes(obj.key))
    if(pathname.split('/').length - 1 > 1) {
      setInnerLink(true)
    }else{
      setInnerLink(false)
    }
    if(currentItem.length){
      const activeObj = Object.assign(...currentItem)
      setActiveItem(activeObj?.key)
    }else{
      setActiveItem('')
    }
  }

  const generateMenuItems = (mobile = false) => {
    const menuItem = (item) => {
      const { key, name } = item    
      return (
        <Link
            key={key}
            to={key}
            className={classNames(
              activeItem === key ? 'bg-slate-200 text-slate-600 text-sm leading-6' : 'text-slate-600 hover:bg-slate-200 text-sm leading-6',
              mobile ? 'bg-slate-800 text-base text-slate-100 hover:text-white hover:bg-slate-600' : '',
              'group flex items-center px-2 py-2 font-medium rounded-md'
            )}
            aria-current={activeItem === key ? 'page' : undefined}
          >
            <item.icon className={classNames('mr-4 flex-shrink-0 h-6 w-6', mobile ? 'text-slate-200' : 'text-slate-600')} aria-hidden="true" />
            {name}
          </Link>
      )
    }

    return menuData.navigation.map((item) => {
      if (item.roles && !item.roles.includes(role)) {
        return null
      }
      return menuItem(item)
    })
  }

  const items = generateMenuItems()

  const mobileItems = generateMenuItems(true)

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}
      <div className="min-h-full z-30">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-slate-700">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-shrink-0 flex items-center px-4">
                  <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/easywire-logo-slate-300-mark-white-text.svg"
                    alt="Easywire logo"
                  />
                </div>
                <nav
                  className="mt-5 flex-shrink-0 h-full divide-y divide-slate-800 overflow-y-auto"
                  aria-label="Sidebar"
                >
                  <div className="px-2 space-y-1">
                    {mobileItems}
                  </div>
                  <div className="mt-6 pt-6">
                    <div className="px-2 space-y-1">
                      {secondaryNavigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="group flex items-center px-2 py-2 text-base font-medium rounded-md text-slate-100 hover:text-white hover:bg-slate-600"
                        >
                          <item.icon className="mr-4 h-6 w-6 text-slate-200" aria-hidden="true" />
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </nav>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:flex lg:w-64 lg:flex-col lg:fixed lg:inset-y-0">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col flex-grow bg-white shadow pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4">
              <img
                className="h-8 w-auto"
                src="./edone-whole.svg"
                alt="EdOne logo"
              />
            </div>
            <nav className="mt-8 flex-1 flex flex-col divide-y divide-slate-200 overflow-y-auto" aria-label="Sidebar">
              <div className="px-2 space-y-1">
                {items}
              </div>
              <div className="mt-6 pt-6">
                <div className="px-2 space-y-1">
                  {secondaryNavigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-slate-600 hover:bg-slate-200"
                    >
                      <item.icon className="mr-4 h-6 w-6 text-slate-600" aria-hidden="true" />
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  )
}

export default withRouter(Sidebar)
