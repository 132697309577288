import { createClient } from 'urql';

const useConfigClient = () => {

  const getToken = () => JSON.parse(localStorage.getItem('accessToken'));
   
  // Initialize Client
  const client = createClient({
    url: `http://${process.env.REACT_APP_GRAPHQL_ENDPOINT}`,
    fetchOptions: () => {
      const token = getToken();
      return {
        headers: { Authorization: token ? `Bearer ${token}` : '' },
      };
    },
  });

  return client;

}

export default useConfigClient;