import {
  BellIcon,
  ClockIcon,
  CogIcon,
  CreditCardIcon,
  DocumentReportIcon,
  HomeIcon,
  MenuAlt1Icon,
  QuestionMarkCircleIcon,
  ScaleIcon,
  LibraryIcon,
  BriefcaseIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XIcon,
  ClipboardListIcon,
  CashIcon,
  AcademicCapIcon,
  BadgeCheckIcon,
} from '@heroicons/react/outline'
import {
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  OfficeBuildingIcon,
  SearchIcon,
} from '@heroicons/react/solid'

export default async function getMenuData() {

  const devMenu = {
      
  }

  const liveMenu = {
    navigation: [
    { 
      name: "Home", 
      key: "/home",  
      icon: HomeIcon,    
    },
    { name: "Campus",
      key: "/campus",
      icon: LibraryIcon
    },
    { name: "Administration",
      key: "/administration",
      icon: BriefcaseIcon
    },
    { 
      name: "Curriculum",
      key: "/curriculum",
      icon: ClipboardListIcon
    },
    { 
      name: "People", 
      key: "/people",
      icon: UserGroupIcon
    }    
    ],
    secondaryNavigation: [
      { 
        name: "Settings", 
        key: "/home",
        icon: CogIcon,        
      },
      { name: "Policies",
        key: "/campus",
        icon: ShieldCheckIcon
      },
      { 
        name: "Help", 
        key: "/help",
        icon: QuestionMarkCircleIcon
      }    
    ]
  }

  if(process.env.NODE_ENV === 'development') return {...liveMenu, ...devMenu}
  
  return {...liveMenu}

}
