import React from 'react'
import { withRouter } from 'react-router-dom'


import Header from 'components/Common/Header'
import Sidebar from 'components/Common/Sidebar'

const MainLayout = ({ children }) => (
    <div className="relative min-h-screen flex flex-col bg-gray-100">
      <Header />
      <Sidebar />
      <main className="flex-1 relative pb-8 overflow-y-auto">
        {children}
      </main>
    </div>
  )

export default withRouter(MainLayout)
