import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { useSelector } from 'react-redux'

import Layout from 'layouts'

const routes = [
  // TUIA:REPLACE-START:ROUTER-CONFIG
  {
    path: '/home',
    Component: lazy(() => import('pages/home')),
    exact: true,
  },
  {
    path: '/campus',
    Component: lazy(() => import('pages/campus')),
    exact: true,
  },
  {
    path: '/administration',
    Component: lazy(() => import('pages/administration')),
    exact: true,
  },
  {
    path: '/curriculum',
    Component: lazy(() => import('pages/curriculum')),
    exact: true,
  },
  {
    path: '/people',
    Component: lazy(() => import('pages/people')),
    exact: true,
  },
  {
    path: '/team',
    Component: lazy(() => import('pages/team')),
    exact: true,
  },
  {
    path: '/profile',
    Component: lazy(() => import('pages/settings/profile')),
    exact: true,
  },
  {
    path: '/shop',
    Component: lazy(() => import('pages/shop')),
    exact: true,
  },
  {
    path: '/people/students',
    Component: lazy(() => import('pages/people/students')),
    exact: true,
  },
  {
    path: '/people/students/:id',
    Component: lazy(() => import('pages/people/student-details')),
    exact: true,
  },
  {
    path: '/shop/checkout',
    Component: lazy(() => import('pages/shop/checkout')),
    exact: true,
  },
  {
    path: '/shop/checkout/result',
    Component: lazy(() => import('pages/shop/checkout/result')),
    exact: true,
  },
  {
    path: '/shop/orders',
    Component: lazy(() => import('pages/shop/orders')),
    exact: true,
  },
  {
    path: '/shop/orders/:id',
    Component: lazy(() => import('pages/shop/orders/order-details')),
    exact: true,
  },
  {
    path: '/shop/category/:id',
    Component: lazy(() => import('pages/shop/category')),
    exact: true,
  },
  {
    path: '/shop/sub-category/:id',
    Component: lazy(() => import('pages/shop/sub-category')),
    exact: true,
  },
  {
    path: '/shop/product/:id',
    Component: lazy(() => import('pages/shop/sub-category/product-details')),
    exact: true,
  },
  {
    path: '/order-internal',
    Component: lazy(() => import('pages/pos/orders')),
    exact: true,
  },
  {
    path: '/order-internal/:id',
    Component: lazy(() => import('pages/pos/orders/order-details')),
    exact: true,
  },
  // TUIA:REPLACE-END:ROUTER-CONFIG
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  }
]

const Router = ({ history }) => {

  const routerAnimation = useSelector(state=>state.settings.routerAnimation)

  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={(state) => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    {/* VB:REPLACE-NEXT-LINE:ROUTER-REDIRECT */}
                    <Route exact path="/" render={() => <Redirect to="/home" />} />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            </div>
                          )}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
        </Layout>
    </ConnectedRouter>
  )
}

export default Router
