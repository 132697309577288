import React from 'react'
import './App.css'
import { createHashHistory } from 'history'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import { Provider as GraphQLProvider, createClient } from 'urql'
import useConfigClient from 'config/graphqlConfig'

import reducers from './redux/reducers'
import sagas from './redux/sagas'
import Router from './routes'



// middlewared
const history = createHashHistory()

const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [sagaMiddleware, routeMiddleware]

const store = createStore(reducers(history), compose(applyMiddleware(...middlewares)))
sagaMiddleware.run(sagas)


console.log('GraphQL Endpoint: ', `http://${process.env.REACT_APP_GRAPHQL_ENDPOINT}`)

const App = () => (
  <GraphQLProvider value={useConfigClient()}>
    <Provider store={store}>
      <Router history={history} />
    </Provider> 
  </GraphQLProvider>
       
  )

export {store, history}

export default App
